import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, Observable, throwError} from "rxjs";
import {environment} from "../../env/environment";

@Injectable({
  providedIn: "root"
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) {
  }
  private url = environment.apiUrl;

  get<T>(path: string, params = {}, api = this.url, headers = {
    "Authorization": "Bearer XwgtlQw0sZ2NJsJHJS8pOM7quIiE1vhzXTu1XbQ1fvwXdEnnpSUsOOdBqS8AKJxLya6QUs8RIwN7FPsvArhGVrfTPTCWT8WWZthkxgUvSTsY3zHoBDIAWMdn2IMZl8l9"
  }): Observable<any> {
    return this.http.get(`${api}${path}`, {
      headers,
      observe: "response",
      params
    }).pipe(catchError(this.formatErrors));
  }

  post<T>(path: string, body = {}, api = this.url, headers = {}): Observable<any> {
    return this.http.post(`${api}${path}`, body,
      {
        headers,
        observe: "response"
      }
    ).pipe(catchError(this.formatErrors));
  }

  postForm<T>(path: string, body: FormData, headers = {}): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, body,
      {
        headers,
        observe: "response"
      }
    ).pipe(catchError(this.formatErrors));
  }


  put<T>(path: string, body: object = {}, headers = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, body,
      {
        headers,
        observe: "response"
      }
    ).pipe(catchError(this.formatErrors));
  }

  delete<T>(path: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${path}`
    ).pipe(catchError(this.formatErrors));
  }

  patch<T>(path: string, params = {}, body: object = {}, headers = {}): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${path}`, body,
      {
        headers,
        observe: "response",
        params
      }
    ).pipe(catchError(this.formatErrors));
  }

  visitorApi<T>(): Observable<any> {
    return this.http.get(`https://api.visitorapi.com/api/?pid=trppJDiR1VD9epeGa43i`);
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
