import {inject, Injectable, Type} from '@angular/core';
import {ActivatedRoute, ParamMap, Router, RouterOutlet} from "@angular/router";
import {ApiService} from "./api.service";
import {BehaviorSubject, catchError, Observable} from "rxjs";
import {JobData} from "../../interfaces/job-data";
import {environment} from "../../env/environment";
import {HttpHeaders,HttpClient} from "@angular/common/http";

const defaultData: JobData = {
  applyUrl: null,
  brandCode: null,
  city: null,
  country: null,
  created: null,
  datePosted: null,
  description: null,
  eRecruitDepartmentId: null,
  eRecruitFolderGroupId: null,
  erecruitComapnyId: null,
  erecruitFolderGroupCategoryId: null,
  erecruitPositionTypeId: null,
  expiryDate: null,
  formattedSalary: null,
  googleJobSearchScript: null,
  id: null,
  isActive: null,
  isRemote: null,
  latitude: null,
  longitude: null,
  modified: null,
  opCo: null,
  ownerId: null,
  positionTypeName: null,
  postalCode: null,
  recruiterEmailAddress: null,
  salaryHigh: null,
  salaryLow: null,
  stateProvince: null,
  title: null,
  brandData:null,
  trackingTags:null,
  promptForPrimaryLanguage:null
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  // public api: ApiService;

  public jobId = new BehaviorSubject(null);
  private jobDataSubject:BehaviorSubject<JobData> = new BehaviorSubject(null);
  public jobData$:Observable<JobData> = this.jobDataSubject.asObservable();
  private adSourceSubject = new BehaviorSubject({});
  private educationLevelsSubject = new BehaviorSubject({});
  private languagesSubject = new BehaviorSubject({});

  private jwtSubject = new BehaviorSubject("");
  private bearerSubject = new BehaviorSubject("");
  public checkCandidateData = null;

  public existingCandidate = false;
  public uploadedResume = false;
  public candidateEmail = null;
  public resumeFileStoreId = null;

  public isNewCandidate = true;
  public usAuthorized = null;
  public sponsorshipStatus = null;
  public ageVerification = null;

  private visitorApiResponse: any;
  public userOriginData: any;

  public brandData: any = {
    brandCode: null,
    brandName: null,
    companyStatement: null,
    headerHtml: null,
    homePageUrl: null,
    logoImageUrl: null,
    promoHtml: null,
    siteIconUrl: null,
    stylesheet: null,
    jobSearchUrl: null
  };
  public trackingTags = new BehaviorSubject(null);
  public thankYouTrackingTags = new BehaviorSubject(null);

  public candidateId = null;
  public adSourceId = null;
  public referrerUrl = null;
  public brandCode = null;
  public UrlAdSourceId = null;


  public userTracking: any = {
    id: 0,
    jobPostingId: null,
    candidateEmailAddress: null,
    candidateId: null,
    returningCandidate: false,
    resumeUploaded: false,
    redirectedToWorkNow: false,
    brandCode: null,
    brandName: null,
    ipAddress: null,
    city: null,
    region: null,
    countryCode: null,
    countryName: null,
    cityLatLong: null,
    browser: null,
    browserVersion: null,
    deviceBrand: null,
    deviceFamily: null,
    deviceModel: null,
    os: null,
    osVersion: null,
    referringUrl: null,
    currentStep: 'init'
  };

  constructor(
      public router: Router,
      private route: ActivatedRoute,
    public api: ApiService = inject(ApiService)
  ) { }

  workNRedirect(jobId) {
    const apiUrl = "Api/WorkN/GetAppRedirectUrlForJob/" + jobId;
    return this.api.get(apiUrl);
  }

  getJobDetails(jobId: any) {

    let pathBrandCode = window.location.pathname.replace('/','')
    if(pathBrandCode.length > 0){
      this.brandCode = pathBrandCode;
    }

    let apiUrl;

    if(this.brandCode == null) {
      apiUrl = 'Job/Search/' + jobId;
    } else {
      apiUrl = this.brandCode + '/Job/Search/' + jobId;
    }
    return this.api.get(
      apiUrl
    )
  }

  clearJobData() {
    this.jobDataSubject.next(null);
  }

  getUserOrigin(){
    // console.log('userDetails')
    this.api.visitorApi()
        .subscribe(
            res => {
              // console.log(res);
              this.userOriginData = res.data
              console.log(this.userOriginData)
              this.userTracking.jobPostingId = this.getJobData().id
              this.userTracking.brandCode = this.brandData.brandCode
              this.userTracking.brandName = this.brandData.brandName
              this.userTracking.ipAddress = this.userOriginData.ipAddress
              this.userTracking.city = this.userOriginData.city
              this.userTracking.region = this.userOriginData.region
              this.userTracking.countryCode = this.userOriginData.countryCode
              this.userTracking.countryName = this.userOriginData.countryName
              this.userTracking.cityLatLong = this.userOriginData.cityLatLong
              this.userTracking.browser = this.userOriginData.browser
              this.userTracking.browserVersion = this.userOriginData.browserVersion
              this.userTracking.deviceBrand = this.userOriginData.deviceBrand
              this.userTracking.deviceFamily = this.userOriginData.deviceFamily
              this.userTracking.deviceModel = this.userOriginData.deviceModel
              this.userTracking.os = this.userOriginData.os
              this.userTracking.osVersion = this.userOriginData.osVersion
              this.userTracking.referringUrl = this.referrerUrl
              this.userTracking.currentStep = 'init'
              // console.log(this.userTracking)
              this.updateCandidateTracking()
                  .subscribe(
                  res => {
//        this.appService.candidateEmail = this.emailAddress;
                    console.log(res)
                    this.userTracking.id = res.body.id
                    console.log(this.userTracking)
                  }
              )

            },
            error => console.error(error),
        )
  }

  checkCandidate(
    emailAddress: string,
    jobId: number,
    candidateId = 0
  ) {
    const apiUrl: string = 'checkCandidate';
    const data = {
      emailAddress,
      jobId,
      candidateId
    }
    return this.api.post(apiUrl, data);
  }

  storeJobData(jobData: JobData) {
    this.jobDataSubject.next(jobData);
  }

  getJobData() {
    return this.jobDataSubject.getValue();
  }

  submitApplication(application) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer 2IPs42mwzjHdK3p7NQdyayOUWMQQkgAozYRiAJkjJNqW768JDzlxF5dJE49ePupId7NsVz9TtItb6gWepgcgPDOc7vpI1IEMoGbwHcYgZ5835tjNdOiHG3L37hCMVQ63',
      'Jwtauthorization': this.getJwtToken(),
      'UserControl': this.getJwtToken()
    });

    const apiUrl = "Candidate/Submit"
    return this.api.post(apiUrl, application, environment.apiUrl, headers);
  }

  setCheckCandidateData(data) {
    this.checkCandidateData = data;
    console.log('this.checkCandidateData')
    console.log(this.checkCandidateData)
    // console.log(this.checkCandidateData.adSource);
    this.setAdsources(
      data.adSource
    )
    this.setLanguages(
        data.languages
    )

    this.setEducationLevels(
      data.educationLevels
    )

    this.setJwtToken(data.jwt)
  }

  setUserTracking(data) {
    this.userTracking = data;
  }

  getUserTracking() {
    return this.userTracking;
  }

  setJwtToken(token) {
    this.jwtSubject.next(token);
  }

  getJwtToken() {
    return this.jwtSubject.getValue();
  }

  setBearerToken(token) {
    this.bearerSubject.next(token);
  }

  getBearerToken() {
    return this.bearerSubject.getValue();
  }

  getCheckCandidateData() {
    return this.checkCandidateData;
  }

  setAdsources(data) {
    this.adSourceSubject.next(data);
  }

  getAdsources() {
    return this.adSourceSubject.getValue();
  }

  setLanguages(data) {
    this.languagesSubject.next(data);
  }

  getLanguages() {
    return this.languagesSubject.getValue();
  }

  getCandidateName() {
    return this.getCheckCandidateData().personalInfo[0].firstName
  }

  getCandidateErecruitId() {
    return this.getCheckCandidateData().erecruitCandidateId

  }

  getHeaders() {

    console.log(this.getJwtToken());

    return new HttpHeaders({
      // @ts-ignore
      Jwtauthorization: this.getJwtToken()
    })
  }

  setEducationLevels(data) {
    this.educationLevelsSubject.next(data);
  }
  getEducationLevels() {
    return this.educationLevelsSubject.getValue();
  }

  uploadResume(formData) {
    const apiUrl = "Candidate/PostResume";
    console.log("Upload");
    console.log(formData);

    const headers = new HttpHeaders({
//      'Content-Type': "multipart/form-data",
      'Authorization': 'Bearer 2IPs42mwzjHdK3p7NQdyayOUWMQQkgAozYRiAJkjJNqW768JDzlxF5dJE49ePupId7NsVz9TtItb6gWepgcgPDOc7vpI1IEMoGbwHcYgZ5835tjNdOiHG3L37hCMVQ63',
      'Jwtauthorization': this.getJwtToken(),
      'UserControl': this.getJwtToken()
    });

    console.log(headers);

    return this.api.postForm(apiUrl, formData, headers);
  }

  lookupReferrer(referrer){
    let referrerPaylod = {
      referrerUrl: referrer
    }
    const apiUrl = "GetReferral"
    return this.api.post(apiUrl, referrerPaylod);
  }

  updateCandidateTracking(){
    const apiUrl = "UpdateProgress"
    // console.log("UpdateProgress");
    // console.log(this.userTracking);
    return this.api.post(apiUrl, this.userTracking);
  }
}
